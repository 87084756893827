:root {
  /* This varaible is used to change the body background color via the useBodyColor hook */
  --body-bg-color: transparent;
}

body {
  opacity: 1;
  background-color: var(--body-bg-color);

  /* Makes fonts on FF render similarly to Chrome */
  -moz-osx-font-smoothing: grayscale;
}

body.dragging {
  user-select: none;
}

.draggable {
  cursor: move;
  z-index: 9999;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

html,
body {
  height: 100%;
}

body,
#__next {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

a {
  color: inherit;
}

.slider-control-bottomcenter {
  transform: translateX(0) !important;
}

.safari .center-box {
  display: block;
}

.slider-frame {
  border-radius: 10px;
  overflow: hidden;
}

[data-reach-dialog-content] {
  outline: none;
}

[data-tippy-root] {
  /* above modals */
  z-index: 2000 !important;
}

[data-floating-ui-portal] {
  /* above modals */
  z-index: 2000 !important;
}

.body-fixed {
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
}

#popover-container {
  position: relative;
  /* above modals */
  z-index: 2000;
}

/* WalletConnect modal. Must be above our Modal */
w3m-modal {
  z-index: 9999;
}

.webkit-fit-content {
  height: -webkit-fit-content;
}

.black-background {
  background-color: #000;
}

.ProseMirror:focus {
  outline: none;
}

.tiptap .is-editor-empty:first-child::before {
  color: rgba(0, 0, 0, 0.4);
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
