/* font imports */

@font-face {
  font-family: 'Suisse';
  src:
    url('/fonts/suisse/SuisseIntl-Book-WebS.woff2') format('woff2'),
    url('/fonts/suisse/SuisseIntl-Book-WebS.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse';
  src:
    url('/fonts/suisse/SuisseIntl-SemiBold-WebS.woff2') format('woff2'),
    url('/fonts/suisse/SuisseIntl-SemiBold-WebS.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse';
  src:
    url('/fonts/suisse/SuisseIntl-Medium-WebS.woff2') format('woff2'),
    url('/fonts/suisse/SuisseIntl-Medium-WebS.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* TODO(LukeBailey): This is currently only needed for the Rodeo Hero, if this page is being remove / update check to make sure if this font can be removed */
@font-face {
  font-family: 'Suisse';
  src:
    url('/fonts/suisse/SuisseIntl-Bold-WebS.woff2') format('woff2'),
    url('/fonts/suisse/SuisseIntl-Bold-WebS.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SuisseMono';
  src:
    url('/fonts/suisse/SuisseIntlMono-Regular-WebS.woff2') format('woff2'),
    url('/fonts/suisse/SuisseIntlMono-Regular-WebS.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Fallback';
  font-style: normal;
  font-weight: 400;
  src: local('Arial');
  ascent-override: 94.87%;
  descent-override: 29.92%;
  line-gap-override: 0%;
  size-adjust: 103.93%;
}

@font-face {
  font-family: 'Suisse Fallback';
  font-style: normal;
  font-weight: 700;
  src: local('Arial');
  ascent-override: 92.57%;
  descent-override: 29.2%;
  line-gap-override: 0%;
  size-adjust: 106.51%;
}

@font-face {
  font-family: 'Suisse Fallback';
  font-style: normal;
  font-weight: 700;
  src: local('Arial');
  ascent-override: 94.03%;
  descent-override: 29.66%;
  line-gap-override: 0%;
  size-adjust: 104.86%;
}
